import {PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import {Site, Sites} from "./types";
import {EmptyConfig} from "../../shared/types";
import {LanguageOutlined} from "@mui/icons-material";
import React, {ReactElement} from "react";
import {EditSiteHelper} from "./EditSiteHelper";
import {StyledBoxColumn, StyledBoxRow, StyledListItem} from "../../shared/StyledComponents";
import {PD_MD} from "../../shared/dimens";
import {Button} from "@mui/material";
import {BaseListFragment} from "../../shared/BaseListFragment";
import {findIcon} from "../../shared/icons";

export type SiteListFragmentProps = PageFragmentProps & {}

type SiteListFragmentState = PageFragmentState & {
  sites: Site[],
}

export class SiteListFragment extends BaseListFragment<Site, Sites, SiteListFragmentProps, SiteListFragmentState> {

  constructor(props: SiteListFragmentProps, context: any) {
    super(props, context, Sites.getInstance());
  }

  protected getIntrinsicHeight(): number | string {
    return null;
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await super.fetchOnMount(forceReload);
    this.setState({
      sites: this.loader.getListItems(),
    });
  }

  onItemChanged(item: Site) {
    this.reload();
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG;
  }

  protected getActionButtonText(): string {
    return "Add new site";
  }

  protected getTitle(): string | null | undefined {
    return "Sites";
  }

  protected getEmptyConfig(): EmptyConfig {
    return {
      title: "No sites found",
      text: "Set up a site to connect with customers",
      iconType: LanguageOutlined,
    };
  }

  onActionButtonClicked(event?) {
    EditSiteHelper.addSite();
  }

  renderContent(): React.ReactElement {
    if (!(this.state.sites?.length > 0)) {
      return this.renderEmpty();
    }
    const sites = this.state.sites;
    return <StyledBoxColumn style={{padding: PD_MD}}>
      {sites.map(site => this.renderSite(site))}
    </StyledBoxColumn>;
  }

  private renderSite(site: Site): ReactElement {
    return <StyledListItem
      title={site.name}
      iconBackgroundColor={site.color}
      icon={findIcon(site.icon)}
      accessory={<StyledBoxRow>
        <Button onClick={event => {
          event.preventDefault();
          EditSiteHelper.editSite(site);
        }}>Settings</Button>
      </StyledBoxRow>}
      onClick={object => {
      }}
    />
  }
}