import {PageFragment, PageFragmentProps, PageFragmentState} from "shared/PageFragment";
import {ReactElement} from "react";
import {Button, Typography} from "@mui/material";
import {DW_SM, PD_MD} from "shared/dimens";
import {LanguageOutlined} from "@mui/icons-material";
import {StyledBoxColumn} from "../../shared/StyledComponents";
import {EditSiteHelper} from "./EditSiteHelper";

export type NewSiteFragmentProps = PageFragmentProps & {}

type NewSiteFragmentState = PageFragmentState & {
  meetingInvite?: string,
}

export class NewSiteFragment extends PageFragment<NewSiteFragmentProps, NewSiteFragmentState> {

  renderContent(): ReactElement {
    return <StyledBoxColumn style={{
      width: DW_SM,
      flexGrow: 1,
      margin: "auto",
      alignItems: "center",
      justifyContent: "center",
      gap: PD_MD,
    }}>
      <img src="/assets/site.svg" style={{width: "100%", aspectRatio: 1}}/>
      <Typography style={{textAlign: "center", padding: PD_MD, marginTop: -40}}>
        A site helps you engage with your audience, build community and moderate discussions. Easy to set up and
        customize, use the embed code to add a comments and feedback section to your site - no coding required.
      </Typography>
      <Button
        variant="contained"
        startIcon={<LanguageOutlined/>}
        onClick={() => EditSiteHelper.addSite()}>
        Create a new site
      </Button>
    </StyledBoxColumn>;
  }
}
