import {DialogFragment, PageFragment, PageFragmentProps} from "shared/PageFragment";
import {Box} from "@mui/material";
import {FormGenContainer, FormGenContainerMode} from "shared/FormGenContainer";
import {DW_LG} from "shared/dimens";
import {App} from "app/App";
import {DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "shared/BaseApp";
import React from "react";
import {Sites, Site} from "./types";

export class EditSiteHelper {

  static addSite() {
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditSiteFragment onSitesaved={site => {
        App.CONTEXT.hideDialog();
        App.CONTEXT.showToast("Site saved.");
      }}/>);
  }

  static editSite(site: Site) {
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditSiteFragment edit={site} onSitesaved={site => {
        App.CONTEXT.hideDialog();
        App.CONTEXT.showToast("Site saved.");
      }}/>);
  }
}

export type EditSiteFragmentProps = PageFragmentProps & {
  edit?: Site,
  onSitesaved: (site: Site) => void,
}

export class EditSiteFragment extends DialogFragment<EditSiteFragmentProps> {

  private readonly sites = Sites.getInstance();
  private readonly site = this.props.edit ? this.props.edit : Site.createNew();

  protected onCreateState() {
    return {
      ...super.onCreateState(),
      title: this.props.edit ? "Edit" : "Add",
    };
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG;
  }

  protected getActionButtonText(): string {
    return this.props.edit ? "Save" : "Add";
  }

  onActionButtonClicked() {
    this.addSite()
      .then(() => this.props.onSitesaved(this.site));
  }

  private async addSite() {
    await this.sites.addListItem(this.site);
  }

  renderContent(): React.ReactElement | null {
    return <Box style={{display: "flex", width: DW_LG}}>
      <FormGenContainer mode={this.props.edit ? FormGenContainerMode.EDIT : FormGenContainerMode.CREATE} content={this.site}/>
    </Box>
  }
}
