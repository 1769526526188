import {DialogFragment, DialogFragmentProps, DialogFragmentState} from "../../shared/PageFragment";
import React from "react";
import {StyledBoxColumn, StyledBoxRow, StyledHorizontalDivider, StyledSpan} from "../../shared/StyledComponents";
import {Button, Typography} from "@mui/material";
import {DW_LG, PD_MD} from "../../shared/dimens";
import {DeleteOutlined, SendOutlined} from "@mui/icons-material";
import {FormGenContainer} from "../../shared/FormGenContainer";
import {Comment, Comments} from "./types";
import {App} from "../App";
import {DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";
import Markdown from "react-markdown";
import stripMarkdown from "strip-markdown";

export class EditCommentHelper {

  static addComment(pageId: string, replyingToSnippet: string, parentId?: string) {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, props =>
      <EditCommentFragment pageId={pageId} replyingToSnippet={replyingToSnippet} parentId={parentId}/>);
  }

  static editComment(comment: Comment) {
    App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, props =>
      <EditCommentFragment edit={comment}/>);
  }
}

type EditCommentFragmentProps = DialogFragmentProps & {
  edit?: Comment,
  pageId?: string,
  replyingToSnippet?: string,
  parentId?: string,
}

type EditCommentFragmentState = DialogFragmentState & {}

class EditCommentFragment extends DialogFragment<EditCommentFragmentProps, EditCommentFragmentState> {

  private readonly comments = new Comments(this.props.edit?.pageId || this.props.pageId);
  private readonly comment = this.props.edit || Comment.createNew(this.props.pageId, this.props.parentId);

  protected getTitle(): string | null | undefined {
    return "Comment";
  }

  private onAddNewComment() {
    this.comments.addListItem(this.comment)
      .then(() => {
        App.CONTEXT.showToast("Comment saved.");
        this.hideDialog();
      })
      .catch(() => App.CONTEXT.showToast("Oops, something went wrong."));
  }

  renderContent(): React.ReactElement {
    return <StyledBoxColumn style={{width: "80vw", maxWidth: DW_LG, gap: 0}}>
      {this.props.replyingToSnippet
        ? <>
          <StyledBoxRow style={{padding: PD_MD, alignItems: "center"}}>
            <Typography style={{flexShrink: 0}}><b>Replying to</b></Typography>
            <Typography component="div"
                        style={{overflowX: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
              <Markdown remarkPlugins={[stripMarkdown]}>
                {this.props.replyingToSnippet}
              </Markdown>
            </Typography>
          </StyledBoxRow>
          <StyledHorizontalDivider/>
        </>
        : null}
      <FormGenContainer content={this.comment}/>
      <StyledHorizontalDivider/>
      <StyledBoxRow style={{padding: PD_MD}}>
        <Button
          startIcon={<DeleteOutlined/>}
          color="error"
          onClick={() => this.hideDialog()}>Discard</Button>
        <StyledSpan/>
        <Button
          startIcon={<SendOutlined/>}
          variant="contained"
          onClick={() => this.onAddNewComment()}>
          Add comment
        </Button>
      </StyledBoxRow>
    </StyledBoxColumn>
  }
}