import {PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import {PageWithSidebarContainer, PageWithSidebarContainerRenderer} from "../../shared/PageWithSidebarContainer";
import React, {ReactElement} from "react";
import {Sidebar, SidebarItem, SidebarItems, SidebarListener} from "../../shared/Sidebar";
import {AddOutlined} from "@mui/icons-material";
import {PathComponent, PathProps} from "../../index";
import {StyledBoxRow, StyledSpan} from "../../shared/StyledComponents";
import {DIVIDER, PD_MD, SZ_SM} from "../../shared/dimens";
import {IconButton, Tooltip, Typography} from "@mui/material";
import {BaseListFragment} from "../../shared/BaseListFragment";
import {App} from "../App";
import {Outlet} from "react-router-dom";
import {Sites, Site} from "./types";
import {NewSiteFragment} from "./NewSiteFragment";
import {findIcon} from "../../shared/icons";
import {PagesFragment} from "./PagesFragment";
import {UnselectedFragment} from "../../shared/UnselectedFragment";
import {EditSiteHelper} from "./EditSiteHelper";

export type SitesFragmentProps = PageFragmentProps & {}

type SitesFragmentState = PageFragmentState & {
  sites: Site[],
  selectedSidebarItem: SidebarItem,
}

const ITEMS: SidebarItems = {
  groups: [
    {
      title: "Sites",
      items: [
        {
          path: "-",
          render: () => <UnselectedFragment/>,
        },
        {
          path: ":site_id",
          nestedPaths: PagesFragment.nestedPaths(),
          render: (pathProps: PathProps) => <PagesFragment path={pathProps}/>,
        },
      ],
      _asyncInit: async (items: SidebarItem[]) => {
        return (await Sites.getInstance().getOrLoadListItems()).map(site => ({
          path: site.id,
          title: site.name,
          iconType: findIcon(site.icon),
          iconBackgroundColor: site.color,
        }));
      },
      // variant: "condensed",
    },
  ],
  containerId: "sites",
};

export class SitesFragment extends BaseListFragment<Site, Sites, SitesFragmentProps, SitesFragmentState> implements PageWithSidebarContainerRenderer, SidebarListener {

  static nestedPaths(): PathComponent[] {
    return PageWithSidebarContainer.nestedPathsFromSidebarItems(ITEMS);
  }

  constructor(props: SitesFragmentProps, context: any) {
    super(props, context, Sites.getInstance());
  }

  protected onCreateState(): SitesFragmentState {
    return {
      ...super.onCreateState(),
      selectedSidebarItem: PageWithSidebarContainer.selectedSidebarItemFromPath(ITEMS, this.props.path),
    };
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await super.fetchOnMount(forceReload);
    this.setState({
      sites: this.loader.getListItems(),
    });
  }

  renderContent(): React.ReactElement {
    if (!this.state.sites) {
      return null;
    }
    return <PageWithSidebarContainer
      size={"sm"}
      sidebarCollapsible
      renderer={this}/>;
  }

  renderPageWithSidebarContainerContent(): React.ReactElement {
    if (!(this.state.sites?.length > 0)) {
      return <NewSiteFragment path={this.props.path}/>;
    }
    return <Outlet/>;
  }

  renderPageWithSidebarContainerSidebar(): React.ReactElement {
    return <Sidebar
      path={this.props.path}
      tabs={[
        {
          id: "site",
          toolbar: this.getSitesToolbar(),
          // action: new Action("Compose", () => this.onNewEmail(), NoteAltOutlined).setVariant("contained"),
          items: ITEMS,
          selected: this.state.selectedSidebarItem,
          listener: this,
        },
      ]}/>;
  }

  onSelectionChanged(item: SidebarItem) {
    this.setState({
      selectedSidebarItem: item,
    });
  }

  private getSitesToolbar(): ReactElement {
    const theme = App.CONTEXT.getAppConfig().theme;
    return <StyledBoxRow style={{
      flexGrow: 1,
      background: theme.palette.background.paper,
      height: SZ_SM,
      borderBottom: DIVIDER,
      alignItems: "center",
      paddingLeft: PD_MD,
      paddingRight: PD_MD
    }}>
      <Typography variant="h6">Sites</Typography>
      <StyledSpan/>
      <Tooltip title="New site">
        <IconButton onClick={() => EditSiteHelper.addSite()}>
          <AddOutlined/>
        </IconButton>
      </Tooltip>
    </StyledBoxRow>
  }
}