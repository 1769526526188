import {DialogFragment, PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import React from "react";
import {Action, ActionActionsListPopover, ActionBase, UserDisplayName} from "../../shared/types";
import {
  EditOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  MoreVertOutlined,
  ReplyOutlined
} from "@mui/icons-material";
import {
  StyledAvatar,
  StyledBoxColumn,
  StyledBoxRow,
  StyledHorizontalDivider,
  StyledMarkdown,
  StyledSpan
} from "../../shared/StyledComponents";
import {PD_LG, PD_MD, SZ_XSM} from "../../shared/dimens";
import {Card, IconButton, Typography} from "@mui/material";
import {BaseContentListItem, Comment, Comments, ContentType, Page} from "./types";
import {DateUtil} from "../../shared/date_util";
import {colorRed} from "../../shared/colors";
import {EditCommentHelper} from "./EditCommentHelper";
import {App} from "../App";

export type PageContentFragmentProps = PageFragmentProps & {
  page: Page,
}

enum SortOrder {
  DESCENDING,
  ASCENDING,
}

type PageContentFragmentState = PageFragmentState & {
  sortOrder: SortOrder,
  comments: Comment[],
}

export class PageContentFragment extends DialogFragment<PageContentFragmentProps, PageContentFragmentState> {

  private readonly actions: ActionBase[] = [
    new ActionActionsListPopover([
      new Action("Descending", () => this.setSortOrder(SortOrder.DESCENDING)),
      new Action("Ascending", () => this.setSortOrder(SortOrder.ASCENDING)),
    ], "Sort"),
  ];

  protected onCreateState(): PageContentFragmentState {
    return {
      ...super.onCreateState(),
      sortOrder: SortOrder.DESCENDING,
    };
  }

  setSortOrder(sortOrder: SortOrder): void {
    this.setState({
      sortOrder: sortOrder,
    });
  }

  componentDidUpdate(prevProps: Readonly<PageContentFragmentProps>, prevState: Readonly<PageContentFragmentState>, snapshot?: any) {
    if (prevProps.page?.id !== this.props.page.id) {
      this.reload();
    }
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    this.setState({
      comments: (await new Comments(this.props.page.id).getOrLoadListItems())?.sort(this.getSortFn()),
    });
  }

  private getSortFn(): (a: BaseContentListItem, b: BaseContentListItem) => number {
    switch (this.state.sortOrder) {
      default:
      case SortOrder.DESCENDING:
        return (a, b) => b.created - a.created;
      case SortOrder.ASCENDING:
        return (a, b) => a.created - b.created;
    }
  }

  private createCommentActions(comment: Comment): Action[] {
    return [
      new Action("Reply", () => EditCommentHelper.addComment(this.props.page.id, comment.text, comment.id), ReplyOutlined),
    ];
  }

  renderContent(): React.ReactElement {
    return <StyledBoxColumn style={{padding: PD_LG, gap: PD_LG}}>
      {this.state.comments?.length > 0
        ? <>
          <StyledBoxRow>
            <StyledSpan/>
            {this.renderToolbarButtonsInToolbarContainer(this.actions)}
          </StyledBoxRow>
          {this.state.comments.map(comment => this.renderContentView(comment, this.createCommentActions(comment)))}
        </>
        : <Typography>No comments yet.</Typography>}
    </StyledBoxColumn>;
  }

  private createContentMoreActions(content: BaseContentListItem) {
    return [
      new Action("Edit", () => {
        switch (content.type) {
          case ContentType.COMMENT:
            EditCommentHelper.editComment(content as Comment);
            break;
        }
      }, EditOutlined),
    ];
  }

  private renderContentView(content: BaseContentListItem, contentActions?: ActionBase[]) {
    return <Card>
      <StyledBoxColumn style={{gap: 0}}>
        <StyledBoxRow style={{padding: PD_MD, gap: PD_LG}}>
          <StyledAvatar member={content.member}/>
          <StyledBoxColumn style={{flexGrow: 1, gap: PD_LG}}>
            <StyledBoxColumn style={{gap: 0}}>
              <StyledBoxRow style={{height: SZ_XSM, alignItems: "center"}}>
                <Typography style={{fontWeight: "bold"}}>{UserDisplayName(content.member.user)}</Typography>
                <StyledSpan/>
                <IconButton
                  onClick={event => App.CONTEXT.showActionsListPopover(event.target as HTMLElement, null, this.createContentMoreActions(content))}>
                  <MoreVertOutlined/>
                </IconButton>
              </StyledBoxRow>
              <StyledBoxRow style={{height: SZ_XSM, alignItems: "center"}}>
                <Typography
                  variant="body2"
                  style={{flexShrink: 0}}>
                  {DateUtil.formatDateTime(content.created)}
                </Typography>
                <StyledSpan/>
              </StyledBoxRow>
            </StyledBoxColumn>
            <StyledMarkdown>
              {content.text}
            </StyledMarkdown>
          </StyledBoxColumn>
        </StyledBoxRow>
        <StyledHorizontalDivider/>
        {this.renderInToolbarContainer1(<>
          <IconButton>
            {content.favorited()
              ? <FavoriteOutlined style={{color: colorRed, width: 16, height: 16}}/>
              : <FavoriteBorderOutlined style={{width: 16, height: 16}}/>}
          </IconButton>
          <StyledSpan/>
          {contentActions?.map(action => this.renderToolbarButtonEx(action))}
        </>)}
      </StyledBoxColumn>
    </Card>;
  }
}