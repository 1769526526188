import {PageWithSidebarContainer, PageWithSidebarContainerRenderer} from "../../shared/PageWithSidebarContainer";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledSpan} from "../../shared/StyledComponents";
import {DIVIDER, PD_MD, PD_SM, PD_XSM, SZ_SM, SZ_SSM, SZ_XSM, SZ_XXXLG} from "../../shared/dimens";
import {ButtonBase, IconButton, Typography} from "@mui/material";
import {ArticleOutlined, FilterListOutlined, SwapVertOutlined} from "@mui/icons-material";
import {BaseApp} from "../../shared/BaseApp";
import {BaseListFragment} from "../../shared/BaseListFragment";
import {FabSpec, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import {colorHighlightAlt} from "../../shared/colors";
import {AbstractListItemsLoader, EmptyConfig} from "../../shared/types";
import {Page, Pages, Site, Sites} from "./types";
import {PageContentFragment} from "./PageContentFragment";
import {DateUtil} from "../../shared/date_util";
import {PathComponent} from "../../index";

export type PagesFragmentProps = PageFragmentProps & {}

type PagesFragmentState = PageFragmentState & {
  site: Site,
  pages: Page[],
  pageId?: string,
  selectedPage?: Page,
}

export class PagesFragment extends BaseListFragment<Page, AbstractListItemsLoader<Page>, PagesFragmentProps, PagesFragmentState> implements PageWithSidebarContainerRenderer {

  static nestedPaths(): PathComponent[] {
    return [
      {
        path: "",
        render: pathProps => null,
      },
      {
        path: ":page_id",
        render: pathProps => null,
      },
    ];
  }

  constructor(props: PagesFragmentProps, context: any) {
    super(props, context, Pages.getInstance(props.path.params.site_id));
  }

  componentDidUpdate(prevProps: Readonly<PagesFragmentProps>, prevState: Readonly<PagesFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    const siteId = this.props.path.params.site_id;
    const pageId = this.props.path.params.page_id;
    if (prevProps.path.params.site_id !== siteId) {
      this.setLoader(Pages.getInstance(siteId));
    } else if (prevProps.path.params.page_id !== pageId) {
      this.update();
    }
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await super.fetchOnMount(forceReload);
    this.update();
  }

  private update() {
    const siteId = this.props.path.params.site_id;
    const pages = this.loader.getListItems().filter(page => page.siteId === siteId);
    const pageId = this.props.path.params.page_id;
    this.setState({
      site: Sites.getInstance().getListItem(siteId),
      pages: pages,
      selectedPage: pageId ? pages.find(page => page.id === pageId) : null,
    });
  }

  renderContent(): React.ReactElement {
    return <PageWithSidebarContainer renderer={this}/>;
  }

  protected getUnselectedConfig(): EmptyConfig {
    return {
      title: "No page selected",
      text: "Select a page from the panel on the left to view.",
      iconType: ArticleOutlined,
    };
  }

  createFabSpec(): FabSpec {
    return super.createFabSpec();
  }

  renderPageWithSidebarContainerContent(): React.ReactElement {
    const page = this.state.selectedPage;
    if (!page) {
      return this.renderUnselected();
    }
    return <PageContentFragment path={this.props.path} page={page}/>;
  }

  renderPageWithSidebarContainerSidebar(): ReactElement {
    const theme = BaseApp.CONTEXT.getAppConfig().theme;
    const pages = this.state.pages;
    return <>
      <StyledBoxColumn style={{gap: 0, flexGrow: 1}}>
        {this.getSidebarToolbar()}
        {(pages?.length > 0)
          ? <StyledBoxColumn style={{gap: 0, flexGrow: 1, background: theme.palette.background.paper}}>
            {pages.map(page =>
              <ButtonBase
                style={{
                  textAlign: "start",
                  background: page.id === this.state.selectedPage?.id ? colorHighlightAlt : null
                }}
                onClick={() => this.props.path.navigate(page.id)}>
                {this.renderPage(page)}
              </ButtonBase>)}
          </StyledBoxColumn>
          : <Typography style={{padding: PD_MD, textAlign: "center"}}>No page</Typography>}
      </StyledBoxColumn>
    </>;
  }

  private getSidebarToolbar(): ReactElement {
    const theme = BaseApp.CONTEXT.getAppConfig().theme;
    return <StyledBoxRow style={{
      position: "sticky",
      left: 0,
      right: 0,
      top: 0,
      zIndex: 1,
      height: SZ_SM,
      borderBottom: DIVIDER,
      background: theme.palette.background.paper,
      paddingLeft: PD_SM,
      paddingRight: PD_SM,
      alignItems: "center"
    }}>
      <Typography variant="h6">{this.state.site?.name}</Typography>
      <StyledSpan/>
      <IconButton>
        <FilterListOutlined/>
      </IconButton>
      <IconButton>
        <SwapVertOutlined/>
      </IconButton>
    </StyledBoxRow>
  }

  private renderPage(page: Page): ReactElement {
    return <StyledBoxRow style={{
      flexGrow: 1,
      borderBottom: DIVIDER,
      paddingLeft: PD_SM,
      paddingRight: PD_SM,
      borderLeft: Sites.getInstance().getListItem(page.siteId).color + " 4px solid",
      alignItems: "center",
    }}>
      <StyledBoxColumn
        style={{flexGrow: 1, height: 80, marginLeft: PD_SM, marginTop: PD_SM, marginBottom: PD_SM, gap: PD_XSM}}>
        <StyledBoxRow style={{height: SZ_XSM}}>
          <Typography component="div" sx={{
            fontWeight: "bold",
            fontSize: "115%",
            width: SZ_XXXLG,
            overflowX: "hidden",
            display: "-webkit-box",
            textOverflow: "ellipsis",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical"
          }}>
            {page.url}
          </Typography>
          <StyledSpan/>
          <Typography variant="body2">
            {DateUtil.formatDateTime(page.created)}
          </Typography>
        </StyledBoxRow>
        <StyledBoxRow style={{height: SZ_SSM}}>
          <Typography
            component="div"
            style={{
              marginTop: -16,
              color: "#666",
            }}
            sx={{
              height: 66,
              overflow: "hidden",
              lineHeight: 1.2,
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
            }}>
            {DateUtil.formatDateTime(page.created)}
          </Typography>
        </StyledBoxRow>
      </StyledBoxColumn>
    </StyledBoxRow>
  }
}