import {JsonObject} from "../../shared/json/json-object";
import {FormGen} from "../../shared/formgen";
import {JsonProperty} from "../../shared/json/json-property";
import {Type} from "../../shared/json/helpers";
import {$KTS, DefaultObjectLoader, KeyTextStrings, TypedObject} from "../../shared/types";

@JsonObject()
export abstract class BaseSettings extends TypedObject {

  constructor() {
    super(null, null);
  }

  protected basePath(): string | null {
    return "todo_settings";
  }

  createDefaultLoader(): DefaultObjectLoader<any> {
    return new DefaultObjectLoader<any>(this.basePath(), this.id, this.getType());
  }
}

@JsonObject()
export class GeneralSettings extends BaseSettings {

  readonly id: string = "general";

  static async load() {
    const obj = new GeneralSettings();
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return GeneralSettings;
  }
}

@JsonObject()
export class SitesSettings extends BaseSettings {

  readonly id: string = "sites";

  static async load() {
    const obj = new SitesSettings();
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return SitesSettings;
  }
}

export enum FontSize {
  SMALL = "small",
  DEFAULT = "default",
  LARGE = "large",
}

const FONT_SIZE_KTS = new KeyTextStrings([
  $KTS(FontSize.SMALL, "Small"),
  $KTS(FontSize.DEFAULT, "Default"),
  $KTS(FontSize.LARGE, "Large"),
]);

@JsonObject()
export class PreferencesSettings extends BaseSettings {

  readonly id = "preferences";

  @FormGen({
    name: "Font size",
    sectionTitle: "Display",
    type: "enum",
    description: "Adjust your font size.",
    enumValues: FONT_SIZE_KTS.values,
    enumDefaultKey: FontSize.DEFAULT,
  })
  @JsonProperty()
  fontSize: string;

  static async load() {
    const obj = new PreferencesSettings();
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return PreferencesSettings;
  }
}

export class Settings {

  private static instance: Settings;

  static getInstance(): Settings {
    return this.instance;
  }

  static async loadInstance() {
    this.instance = new Settings(
      await GeneralSettings.load(),
      await SitesSettings.load(),
      await PreferencesSettings.load(),
    );
  }

  constructor(readonly general: GeneralSettings, readonly sites: SitesSettings, readonly preferences: PreferencesSettings) {
  }
}