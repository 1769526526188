import React, {ReactElement} from 'react';
import {PathComponent, PathProps} from "../index";
import {TabInfo} from "../shared/TabsContainer";
import {LanguageOutlined, SettingsOutlined} from "@mui/icons-material";
import {FyneappsApp, FyneappsTabsMain} from "../fyneapps-shared/FyneappsApp";
import {SettingsFragment} from "./settings/SettingsFragment";
import {User} from "@firebase/auth";
import {Settings} from "./settings/settings";
import {Outlet} from "react-router-dom";
import {ProvisionedAppConfig} from "../shared/types";
import {SitesFragment} from "./sites/SitesFragment";
import {ViewPageFragment} from "./sites/ViewPageFragment";
import {Sites} from "./sites/types";
import {LoginConfig} from "../shared/Login";

export enum TabType {
  SITES = "sites",
  SETTINGS = "settings",
}

class Main extends FyneappsTabsMain {

  static appTabs(): TabInfo<string>[] {
    return [
      {
        type: TabType.SITES,
        path: "sites",
        text: "Sites",
        iconType: LanguageOutlined,
        nestedPaths: SitesFragment.nestedPaths(),
        render: (path: PathProps) => <SitesFragment path={path}/>,
      },
      {
        type: TabType.SETTINGS,
        path: "settings",
        text: "Settings",
        iconType: SettingsOutlined,
        nestedPaths: SettingsFragment.nestedPaths(),
        render: (path: PathProps) => <SettingsFragment path={path}/>,
      },
    ];
  }

  protected renderContainerContent(): React.ReactElement | null {
    if (this.props.path?.matches.find(match => match.handle?.containerId === "focus")) {
      return <Outlet/>;
    }
    return super.renderContainerContent();
  }
}

export class App extends FyneappsApp {

  protected getLoginConfig(): LoginConfig | null | undefined {
    return {
      ...super.getLoginConfig(),
      noLogin: true,
    }
  }

  protected async onAppInitWithLoggedInUser(user: User): Promise<void> {
    await super.onAppInitWithLoggedInUser(user);
    await Sites.getInstance().loadListItems();
    await Settings.loadInstance();
  }

  getProvisionedAppId(): string {
    return "feedback";
  }

  getProvisionedAppConfig(): ProvisionedAppConfig {
    return undefined;
  }

  protected getAppName(): string {
    return "Feedback";
  }

  protected getIconName(): string {
    return "@icon/comment_quote";
  }

  static nestedPaths(): PathComponent[] {
    return [
      ...Main.nestedPaths(),
      {
        path: "v/:site_id/:page_url",
        handle: {containerId: "viewer", path: "v/:site_id/:page_url"},
        render: pathProps => <ViewPageFragment path={pathProps} />,
      },
    ];
  }

  protected renderMain(): ReactElement {
    if (this.props.path?.matches.find(match => match.handle?.containerId === "viewer")) {
      return <Outlet/>;
    }
    if (!this.hasAuthCredentials()) {
      return this.renderLogin();
    }
    return <Main path={this.props.path}/>;
  }
}