import {JsonObject} from "../../shared/json/json-object";
import {JsonProperty} from "../../shared/json/json-property";
import {FormGen} from "../../shared/formgen";
import {$KTS, AbstractListItemsLoader, BaseListItem, BaseListItemsLoader, KeyTextStrings} from "../../shared/types";
import {md5_uuid} from "../../shared/md5";
import {JSON_OBJECT} from "../../shared/json/helpers";
import {getMemberAuth} from "../../shared/auth";
import {Member} from "../../shared/entities";
import {ProvisioningContext} from "../../shared/database";

export enum ContentType {
  COMMENT = "comment",
}

export abstract class BaseContentListItem extends BaseListItem {

  @FormGen({name: "Text", type: "rich_text", placeholder: "Type here using Markdown syntax.", size: "small"})
  @JsonProperty()
  text: string;

  // @FormGen({name: "Attachments", type: "array_file", fileMimeTypes: ["image/png", "image/jpg"]})
  // @JsonProperty()
  // attachments: string[];

  constructor(id: string, creator: string, created: number, readonly type: ContentType) {
    super(id, creator, created);
  }

  abstract favorited(): boolean;

  abstract setFavorited(favorited: boolean): void;

  abstract getFavoritedByMembers(): Member[];
}

@JsonObject()
export class Page extends BaseListItem {

  @JsonProperty()
  readonly url: string;
  @JsonProperty()
  readonly siteId: string;

  constructor(id: string, creator: string, created: number, url: string, siteId: string) {
    super(id, creator, created);
    this.url = url;
    this.siteId = siteId;
  }

  static sortOrder(item1: Page, item2: Page): number {
    return item2.created - item1.created;
  }
}

export class Pages extends BaseListItemsLoader<Page> {

  private static readonly instances = new Map<string, AbstractListItemsLoader<Page>>();

  static getInstance(siteId: string): AbstractListItemsLoader<Page> {
    if (!siteId) {
      return null;
    }
    let instance: AbstractListItemsLoader<Page> = this.instances.get(siteId);
    if (instance) {
      return instance;
    }
    instance = new Pages(siteId);
    this.instances.set(siteId, instance);
    return instance;
  }

  private constructor(readonly siteId: string) {
    super({shared: true, overrideProvisioningContext: ProvisioningContext.MAIN});
  }

  protected basePath(): string {
    return "pages/" + this.siteId;
  }

  protected deserializeItem(value: any): Page {
    return JSON_OBJECT.deserializeObject(value, Page);
  }

  protected serializeItem(item: Page): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Page, item2: Page): number {
    return Page.sortOrder(item1, item2);
  }
}

@JsonObject()
export class CommentFavorite extends BaseListItem {

  static createNew(): CommentFavorite {
    return new CommentFavorite(getMemberAuth().getMemberId(), Date.now());
  }

  constructor(creator: string, created: number) {
    super(creator, creator, created);
  }
}

export class CommentFavorites extends BaseListItemsLoader<CommentFavorite> {

  constructor(readonly commentId: string) {
    super({shared: true});
  }

  protected basePath(): string {
    return "comment_favorites/" + this.commentId;
  }

  protected deserializeItem(value: any): CommentFavorite {
    return JSON_OBJECT.deserializeObject(value, CommentFavorite);
  }

  protected serializeItem(item: CommentFavorite): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: CommentFavorite, item2: CommentFavorite): number {
    return item1.created - item2.created;
  }
}

@JsonObject()
export class Comment extends BaseContentListItem {

  @JsonProperty()
  readonly pageId: string;

  @JsonProperty()
  readonly parentId: string;

  private commentFavorites: CommentFavorite[];
  private _favorited: boolean;

  favorited(): boolean {
    if (this._favorited !== undefined) {
      return this._favorited;
    }
    const memberId = getMemberAuth()?.getMemberId();
    return memberId && Boolean(this.commentFavorites?.find(favorite => favorite.creator === memberId));
  }

  setFavorited(favorited: boolean): void {
    if (this._favorited === favorited) {
      return;
    }
    this._favorited = favorited;
    const loader = new CommentFavorites(this.id);
    if (favorited) {
      loader.addListItem(CommentFavorite.createNew())
    } else {
      loader.deleteListItemById(CommentFavorite.createNew().id);
    }
  }

  getFavoritedByMembers(): Member[] {
    return this.commentFavorites.map(favorite => favorite.member);
  }

  static createNew(pageId: string, parentId?: string) {
    const memberAuth = getMemberAuth();
    if (!memberAuth) {
      return null;
    }
    return new Comment(md5_uuid(), memberAuth.getMemberId(), Date.now(), pageId, parentId);
  }

  constructor(id: string, creator: string, created: number, pageId: string, parentId?: string) {
    super(id, creator, created, ContentType.COMMENT);
    this.pageId = pageId;
    this.parentId = parentId;
  }

  async onAfterItemDeserialized(): Promise<void> {
    this.commentFavorites = await new CommentFavorites(this.id).getOrLoadListItems();
  }
}

export class Comments extends BaseListItemsLoader<Comment> {

  constructor(readonly pageId: string) {
    super({shared: true, overrideProvisioningContext: ProvisioningContext.MAIN});
  }

  protected basePath(): string {
    return "comments/" + this.pageId;
  }

  protected deserializeItem(value: any): Comment {
    return JSON_OBJECT.deserializeObject(value, Comment);
  }

  protected serializeItem(item: Comment): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Comment, item2: Comment): number {
    return item1.created - item2.created;
  }
}

export enum SiteCategory {
  BUSINESS = "business",
  CELEBRITY = "celebrity",
  CULTURE = "culture",
}

export const SITE_CATEGORIES = new KeyTextStrings([
  $KTS(SiteCategory.BUSINESS, "Business"),
  $KTS(SiteCategory.CELEBRITY, "Celebrity"),
  $KTS(SiteCategory.CULTURE, "Culture"),
]);

@JsonObject()
export class Site extends BaseListItem {

  @FormGen({name: "Name", type: "string", description: "Enter the name of your site"})
  @JsonProperty()
  name: string;

  @FormGen({name: "Category", type: "enum", enumValues: SITE_CATEGORIES.values})
  @JsonProperty()
  category: string;

  @FormGen({name: "Icon", type: "icon"})
  @JsonProperty()
  icon: string;

  @FormGen({name: "Theme color", type: "color"})
  @JsonProperty()
  color: string;

  static createNew(): Site {
    return new Site(md5_uuid());
  }

  constructor(id: string) {
    super(id, null, null);
  }
}

export class Sites extends BaseListItemsLoader<Site> {

  private static instance: Sites;

  static getInstance(): Sites {
    if (!this.instance) {
      this.instance = new Sites();
    }
    return this.instance;
  }

  private constructor() {
    super({shared: true, overrideProvisioningContext: ProvisioningContext.MAIN});
  }

  protected basePath(): string {
    return "sites";
  }

  protected deserializeItem(value: any): Site {
    return JSON_OBJECT.deserializeObject(value, Site);
  }

  protected serializeItem(item: Site): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Site, item2: Site): number {
    return 0;
  }
}