import {DialogFragment, DialogFragmentProps, DialogFragmentState} from "../../shared/PageFragment";
import {Comment, Comments, Page, Pages, Site, Sites} from "./types";
import React, {createRef, ReactElement} from "react";
import {StyledAvatar, StyledBoxColumn, StyledBoxRow, StyledContainer, StyledSpan} from "../../shared/StyledComponents";
import {Button, Card, IconButton, Link, Typography} from "@mui/material";
import {PD_LG, PD_MD, PD_XLG, PD_XXLG, SZ_SM, SZ_SSM} from "../../shared/dimens";
import {BaseApp, DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";
import {HtmlFragment} from "../../shared/HtmlFragment";
import {AboutFragment} from "../../shared/AboutFragment";
import fyneappsLogotype from "fyneapps-shared/res/fyneapps_logotype.png";
import {md5} from "../../shared/md5";
import {FormGenContainer} from "../../shared/FormGenContainer";
import {App} from "../App";
import {PageContentFragment} from "./PageContentFragment";
import {MoreVertOutlined, SendOutlined} from "@mui/icons-material";
import {Action, ResizeWindowMessage, UserDisplayName} from "../../shared/types";
import {getAuth, signOut} from "@firebase/auth";
import {getMemberAuth} from "../../shared/auth";
import {FyneappsApp} from "../../fyneapps-shared/FyneappsApp";
import {JSON_OBJECT} from "../../shared/json/helpers";

export type ViewPageFragmentProps = DialogFragmentProps & {
  siteId?: string,
  pageId?: string,
}

type ViewPageFragmentState = DialogFragmentState & {
  site: Site,
  page: Page,
}

export class ViewPageFragment extends DialogFragment<ViewPageFragmentProps, ViewPageFragmentState> {

  private readonly memberAuth = getMemberAuth();
  private readonly containerRef = createRef<HTMLDivElement>();

  private comments: Comments;
  private comment: Comment;

  private readonly resizeObserver = new ResizeObserver(entries => {
    const resize = new ResizeWindowMessage();
    resize.height = window.document.body.scrollHeight;
    window.parent?.postMessage(JSON_OBJECT.serializeObject(resize), "*");
  });

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const siteId = this.props.siteId || this.props.path.params.site_id;
    if (!siteId) {
      return;
    }
    const site = await Sites.getInstance().getOrLoadItem(siteId);
    if (!site) {
      return;
    }
    const pageUrl = this.props.path.params.page_url;
    const pageId: string = this.props.pageId || (pageUrl ? md5(pageUrl) : "__page_url_missing");
    if (!pageId) {
      return;
    }
    const pages = Pages.getInstance(siteId);
    let page = await pages.getOrLoadItem(pageId);
    if (!page) {
      page = new Page(pageId, null, Date.now(), pageUrl, siteId);
      await pages.addListItem(page);
    }
    this.setState({
      site: site,
      page: page,
    });
    this.comments = new Comments(pageId);
    this.comment = Comment.createNew(pageId, siteId);
  }

  protected containerContentDidRender() {
    super.containerContentDidRender();
    if (this.containerRef.current) {
      this.resizeObserver.observe(this.containerRef.current);
    }
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
    super.componentWillUnmount();
  }

  renderContent(): ReactElement {
    const site = this.state.site;
    const page = this.state.page;
    if (!site || !page) {
      return this.renderNotFound();
    }
    let rendered = this.renderContentView(page);
    if (!rendered) {
      rendered = this.renderContentError(page);
    }
    return <div ref={this.containerRef}>
      <StyledBoxColumn style={{position: "relative", flexGrow: 1}}>
        <div style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: null,//site.properties.themeColor,
          opacity: 0.1
        }}/>
        <StyledContainer style={{
          zIndex: 1,
          gap: PD_XLG,
          paddingTop: PD_XXLG,
          paddingBottom: PD_XXLG
        }}>
          <Card>
            {rendered}
          </Card>
          <a href="https://feedback.fyneapps.com" style={{alignSelf: "center"}}>
            <StyledBoxRow style={{alignItems: "center", gap: 0, opacity: 0.33}}>
              <img src={fyneappsLogotype} style={{height: SZ_SSM, marginBottom: -4}}/>
              <Typography style={{fontSize: "125%", fontWeight: 600}}>Feedback</Typography>
            </StyledBoxRow>
          </a>
          <Typography variant="body2">
            Never submit sensitive information such as passwords through Fyneapps Feedback. This content is
            created by the owner of the site. The data you submit will be sent to the page owner. Fyneapps is not
            responsible for the privacy or security practices of its customers, including those of this page owner.
          </Typography>
          <StyledBoxRow style={{alignItems: "center", gap: PD_XLG, padding: PD_MD, justifyContent: "center"}}>
            <Typography variant="caption">
              <a href="#"
                 onClick={() => BaseApp.CONTEXT.showDialog(null, () =>
                   <HtmlFragment
                     url="/privacy.html"/>)}>
                Privacy Policy
              </a>
            </Typography>
            <Typography variant="caption">
              <a href="#"
                 onClick={() => BaseApp.CONTEXT.showDialog(null, () =>
                   <HtmlFragment
                     url="/terms.html"/>)}>
                Terms
              </a>
            </Typography>
            <Typography variant="caption">
              <a href="#"
                 onClick={() => BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, () =>
                   <AboutFragment/>)}>
                About
              </a>
            </Typography>
          </StyledBoxRow>
        </StyledContainer>
      </StyledBoxColumn>
    </div>;
  }

  private createMoreActions() {
    return [
      new Action("Logout", () => {
        signOut(getAuth()).then(() => window.location.reload());
      }),
    ];
  }

  private renderContentView(page: Page): ReactElement {
    const site = this.state.site;
    return <Card>
      <StyledBoxColumn style={{padding: PD_XLG, gap: PD_XLG, borderTop: site.color + " 8px solid"}}>
        <Typography variant="h4">Comments</Typography>
        {this.memberAuth && this.comment
          ? <StyledBoxRow style={{padding: PD_MD, gap: PD_LG}}>
            <StyledAvatar member={this.memberAuth.member}/>
            <StyledBoxColumn style={{flexGrow: 1, gap: PD_LG}}>
              <StyledBoxColumn style={{gap: 0}}>
                <StyledBoxRow style={{height: SZ_SM, alignItems: "center"}}>
                  <Typography style={{fontWeight: "bold"}}>{UserDisplayName(this.memberAuth.member.user)}</Typography>
                  <StyledSpan/>
                  <IconButton
                    onClick={event => App.CONTEXT.showActionsListPopover(event.target as HTMLElement, null, this.createMoreActions())}>
                    <MoreVertOutlined/>
                  </IconButton>
                </StyledBoxRow>
              </StyledBoxColumn>
              <FormGenContainer style={{marginLeft: -12, marginTop: -12}} content={this.comment}/>
              <Button
                variant="contained"
                startIcon={<SendOutlined/>}
                style={{margin: PD_MD, marginTop: 0, background: site.color}}
                onClick={() => this.onAddComment()}>
                Add comment
              </Button>
            </StyledBoxColumn>
          </StyledBoxRow>
          : <Link style={{cursor: "pointer"}} onClick={() => (BaseApp.CONTEXT as FyneappsApp).doLogin()}>
            Sign in to add a comment
          </Link>}
        <PageContentFragment page={page}/>
      </StyledBoxColumn>
    </Card>;
  }

  private onAddComment() {
    this.comments.addListItem(this.comment)
      .then(() => {
        App.CONTEXT.showToast("Comment saved.");
        this.hideDialog();
      })
      .catch(() => App.CONTEXT.showToast("Oops, something went wrong."));
  }

  private renderContentSaving(page: Page): ReactElement {
    return this.renderContentInDefaultContainer(page, this.renderPleaseWait());
  }

  private renderContentSaved(page: Page): ReactElement {
    return this.renderContentInDefaultContainer(page, <>
      <Typography>Your answer has been recorded, thank you.</Typography>
    </>);
  }

  private renderContentError(page: Page): ReactElement {
    return this.renderContentInDefaultContainer(page, <Typography>Oops. Something went wrong. Please try again
      later.</Typography>);
  }

  private renderContentLoading(page: Page): ReactElement {
    return this.renderContentInDefaultContainer(page, this.renderPleaseWait());
  }

  private renderContentInDefaultContainer(page: Page, element: ReactElement): ReactElement {
    return <StyledBoxColumn style={{flexGrow: 1, alignItems: "center", justifyContent: "center", padding: PD_MD}}>
      {element}
    </StyledBoxColumn>;
  }
}